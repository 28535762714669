import React from 'react'
import Navbar from '../Navbar';
import NavbarTabs from '../../NavbarTabs/NavbarTabs';
import WebFooter from '../WebFooter';
import { useSelector } from 'react-redux';
import bookimg from "../../../Assests/books.jpeg"
export default function RequestForBooksAndResearchPpr() {
  const navbarFont = useSelector((state) => state.navbarFontSlice);
  const paraFont = useSelector((state) => state.fontSizeSlice);
  const headingFont = useSelector((state) => state.headingFontSlice);
  const language = useSelector((state) => state.globalLanguage);
  return (
    <div>
      <Navbar/>
      <div className='NavbarTabs'><NavbarTabs/></div>
      <div>
      <div className='bg-primarybg py-3'>
        <span 
                className="font-semibold text-black mb-[20px] mx-auto w-fit" 
                style={{ fontSize: `${headingFont}px` }} 
                >
                {language ? "Request for book purchase/ Research paper": "पुस्तक खरीद/शोध पत्र के लिए अनुरोध" } 
            </span>
        </div>
        
        <div className='bg-white flex justify-between items-end team-cards responsive-padding-for-all' style={{padding:'20px 50px 60px'}}>
              {/* Card 1 */}
              <div className="w-[45%] cursor-pointer team-card-width teammate1">
              <div className="bg-primarybg p-2 rounded-3xl shadow-md">
                  <div className="flex items-center  bg-white rounded-2xl ">
                    <img
                      src={bookimg}
                      className="w-1/3 aspect-square rounded-2xl  mr-4"
                      />
                    <div className="text-left" style={{ fontSize: `${paraFont}px` }}>
                      <h3 className="text-lg font-bold ">{language ? "For Procurement of Book/s": "पुस्तकों की खरीद के लिए" } </h3>
                      <h3 className="text-lg font-bold ">{language ? "PLEASE WRITE TO": "कृपया इन्हें लिखें" } </h3>
                      <a 
                        className='italic text-primary underline'
                        href='mailto:acquisition@iitr.ac.in'
                        >
                        Acquisition Section (acquisition@iitr.ac.in) 
                        </a>
                    </div>
                  </div>
                  </div>
                  </div>
              {/* Card 2 */}
              <div className="w-[45%] cursor-pointer team-card-width teammate2">
              <div className="bg-primarybg p-2 rounded-3xl shadow-md">
              <div className="flex items-center bg-white rounded-2xl ">
                <img
                  src={bookimg} 
                  className="w-1/3 aspect-square rounded-2xl mr-4"
                />
                <div  className="text-left" style={{ fontSize: `${paraFont}px` }}>
                  <h3 className="text-lg font-bold ">{language ? "For Article/ Research Paper": "आलेख/शोध पत्र के लिए" }</h3>
                  <h3 className="text-lg font-bold ">{language ? "PLEASE WRITE TO": "कृपया इन्हें लिखें" }</h3>
                  <a 
                    className='italic text-primary underline'
                    href='mailto:journals@iitr.ac.in'
                    >
                    Periodical Section (journals@iitr.ac.in) 
                    </a>
                </div>
              </div>
              </div>
              </div>

        </div>
      </div>
      <WebFooter/>
    </div>
  )
}
