import React from 'react'
import Navbar from './Navbar'
import Banner from './Banner'
import NavbarTabs from '../NavbarTabs/NavbarTabs'
import ServicesInBoxes from './ServicesInBox/ServicesInBoxes'
import NewsAndAnnouncement from './NewsAndAnnouncement'
import PictureBanner from './PictureBanner'
import ImportantLinks from './ImpLinks/ImportantLinks'
import PhotoGallery from './PhotoGallery'
import WebFooter from './WebFooter'
export default function LibraryMainPage() {
  return (
    <div className='overflow-y-scroll h-screen'>
      <Navbar/>
      <Banner/>
      <div className='NavbarTabs'><NavbarTabs/></div>
      <ServicesInBoxes/>
      <NewsAndAnnouncement/>
      <PictureBanner/>
      <ImportantLinks/>
      <PhotoGallery/>
      <WebFooter/>
    </div>
  )
}
