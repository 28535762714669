import React from 'react'
import "./AboutUs.css"
import NavbarTabs from "../NavbarTabs"
import Navbar from "../../Home/Navbar"
import WebFooter from "../../Home/WebFooter"
import { useSelector } from 'react-redux';
import librarian from "../../../Assests/librarian.jpeg"
export default function OurTeam() {
  const navbarFont = useSelector((state) => state.navbarFontSlice);
  const paraFont = useSelector((state) => state.fontSizeSlice);
  const headingFont = useSelector((state) => state.headingFontSlice);
  const language = useSelector((state) => state.globalLanguage);
  const librarians = [
    {
      image: librarian ,
      name: "Dr. Sanjeev K Sunny",name2:"डॉ. संजीव के सनी",
      title: "Librarian (Officiating)", title2:"लाइब्रेरियन (कार्यवाहक)",
      phone: "01332-285939, 285239",
      email1: "library@iitr.ac.in",
      email2: "sksunnymgcl@iitr.ac.in",
    },
    {
      image: librarian ,
      name: "Dr. Sanjeev K Sunny",name2:"डॉ. संजीव के सनी",
      title: "Librarian (Officiating)", title2:"लाइब्रेरियन (कार्यवाहक)",
      phone: "01332-285939, 285239",
      email1: "library@iitr.ac.in",
      email2: "sksunnymgcl@iitr.ac.in",
    },
    {
      image: librarian ,
      name: "Dr. Sanjeev K Sunny",name2:"डॉ. संजीव के सनी",
      title: "Librarian (Officiating)", title2:"लाइब्रेरियन (कार्यवाहक)",
      phone: "01332-285939, 285239",
      email1: "library@iitr.ac.in",
      email2: "sksunnymgcl@iitr.ac.in",
    },
    {
      image: librarian ,
      name: "Dr. Sanjeev K Sunny",name2:"डॉ. संजीव के सनी",
      title: "Librarian (Officiating)", title2:"लाइब्रेरियन (कार्यवाहक)",
      phone: "01332-285939, 285239",
      email1: "library@iitr.ac.in",
      email2: "sksunnymgcl@iitr.ac.in",
    },
    {
      image: librarian ,
     name: "Dr. Sanjeev K Sunny",name2:"डॉ. संजीव के सनी",
      title: "Librarian (Officiating)", title2:"लाइब्रेरियन (कार्यवाहक)",
      phone: "01332-285939, 285239",
      email1: "library@iitr.ac.in",
      email2: "sksunnymgcl@iitr.ac.in",
    },
    {
      image: librarian ,
     name: "Dr. Sanjeev K Sunny",name2:"डॉ. संजीव के सनी",
      title: "Librarian (Officiating)", title2:"लाइब्रेरियन (कार्यवाहक)",
      phone: "01332-285939, 285239",
      email1: "library@iitr.ac.in",
      email2: "sksunnymgcl@iitr.ac.in",
    },
    {
      image: librarian ,
     name: "Dr. Sanjeev K Sunny",name2:"डॉ. संजीव के सनी",
      title: "Librarian (Officiating)", title2:"लाइब्रेरियन (कार्यवाहक)",
      phone: "01332-285939, 285239",
      email1: "library@iitr.ac.in",
      email2: "sksunnymgcl@iitr.ac.in",
    },
    {
      image: librarian ,
     name: "Dr. Sanjeev K Sunny",name2:"डॉ. संजीव के सनी",
      title: "Librarian (Officiating)", title2:"लाइब्रेरियन (कार्यवाहक)",
      phone: "01332-285939, 285239",
      email1: "library@iitr.ac.in",
      email2: "sksunnymgcl@iitr.ac.in",
    },
    {
      image: librarian ,
     name: "Dr. Sanjeev K Sunny",name2:"डॉ. संजीव के सनी",
      title: "Librarian (Officiating)", title2:"लाइब्रेरियन (कार्यवाहक)",
      phone: "01332-285939, 285239",
      email1: "library@iitr.ac.in",
      email2: "sksunnymgcl@iitr.ac.in",
    },
    {
      image: librarian ,
     name: "Dr. Sanjeev K Sunny",name2:"डॉ. संजीव के सनी",
      title: "Librarian (Officiating)", title2:"लाइब्रेरियन (कार्यवाहक)",
      phone: "01332-285939, 285239",
      email1: "library@iitr.ac.in",
      email2: "sksunnymgcl@iitr.ac.in",
    },
  ];
  return (
    <div>
      <Navbar/>
      <div className='NavbarTabs'><NavbarTabs/></div>
      <div>
        <div className='bg-primarybg py-3'>
        <span 
                className="font-semibold text-black mb-[20px] mx-auto w-fit" 
                style={{ fontSize: `${headingFont}px` }} 
                >
                {language ? "MGCL Team": "MGCL टीम" } 
            </span>
        </div>
        
        <div className='bg-white flex justify-between items-end team-cards responsive-padding-for-all' style={{padding:'20px 50px 60px'}}>
              {/* Card 1 */}
              <div className="w-[45%] cursor-pointer team-card-width teammate1">
              <div className="bg-primarybg p-2 rounded-3xl shadow-md">
                  <div className="flex items-center  bg-white rounded-2xl ">
                    <img
                      src={librarian}
                      alt="Dr. Sanjeev K Sunny"
                      className="w-1/3 aspect-square rounded-2xl  mr-4"
                      />
                    <div className="text-left" style={{ fontSize: `${paraFont}px` }}>
                      <h3 className="text-lg font-bold ">{language ? "Dr. Sanjeev K Sunny": "डॉ. संजीव के सनी" } </h3>
                      <p>{language ? "Librarian (Officiating) ": "लाइब्रेरियन (कार्यवाहक)" }</p>
                      <p>Tel.: 01332-285939, 285239</p>
                      <p>E-mail: library@iitr.ac.in</p>
                      <p>sksunnymgcl@iitr.ac.in</p>
                    </div>
                  </div>
                  </div>
                  </div>
              {/* Card 2 */}
              <div className="w-[45%] cursor-pointer team-card-width teammate2">
              <h2 className="font-semibold" style={{ fontSize: `${headingFont}px` }}> {language ? "CHAINMAN LAC ": "चेनमैन एलएसी" }</h2>
              <div className="bg-primarybg p-2 rounded-3xl shadow-md">
              <div className="flex items-center bg-white rounded-2xl ">
                <img
                  src={librarian} 
                  alt="Prof. (Dr.) Mahendra Singh"
                  className="w-1/3 aspect-square rounded-2xl mr-4"
                />
                <div  className="text-left" style={{ fontSize: `${paraFont}px` }}>
                  <h3 className="text-lg font-bold ">{language ? "Dr. Mahendra Singh": "डॉ. महेंद्र सिंह" }</h3>
                  <p>{language ? "Professor ": "प्रोफ़ेसर" }</p>
                  <p>{language ? "Civil Engineering Department": "सिविल इंजीनियरिंग विभाग" }</p>
                  <p>Tel.: 01332-285651</p>
                  <p>mahendra.singh@ce.iitr.ac.in</p>
                </div>
              </div>
              </div>
              </div>

        </div>
        <div className='bg-primarybg flex flex-wrap justify-between items-end team-cards responsive-padding-for-all' style={{padding:'0px 50px 60px'}}>
        {librarians.map((librarian, index) => (
        <div key={index} className="w-[45%] cursor-pointer mt-[60px] team-card-width">
              <div className="bg-white p-2 rounded-3xl shadow-md">
                  <div className="flex items-center  bg-primarybg rounded-2xl ">
                    <img
                      src={librarian.image}
                      alt={librarian.image}
                      className="w-1/3 aspect-square rounded-2xl  mr-4"
                      />
                    <div className="text-left" style={{ fontSize: `${paraFont}px` }}>
                      <h3 className="text-lg font-bold ">{language ? librarian.name: librarian.name2 }</h3>
                      <p>{language ? librarian.title: librarian.title2 }</p>
                      <p>Tel.: {librarian.phone}</p>
                      <p>E-mail: {librarian.email1}</p>
                      <p>{librarian.email2}</p>
                    </div>
                  </div>
                </div>
               </div>
                   ))}
        </div>
      </div>
      <WebFooter/>
    </div>
  )
}
