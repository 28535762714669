import React, { useState, useEffect, useRef } from 'react';
import { showLang } from "../../../Redux/Slices/globalLanguage"
import Navbar from '../Navbar';
import NavbarTabs from '../../NavbarTabs/NavbarTabs';
import WebFooter from '../WebFooter';
import "../LibraryMainPage.css"
import { useDispatch, useSelector } from "react-redux";
export default function FAQ() {
  const navbarFont = useSelector((state) => state.navbarFontSlice);
    const paraFont = useSelector((state) => state.fontSizeSlice);
    const headingFont = useSelector((state) => state.headingFontSlice);
    const language = useSelector((state) => state.globalLanguage);
    const faqData = [
        { question: "How do I borrow a book?",
            question2:"मैं किताब कैसे उधार ले सकता हूँ?",
             answer: "Here is the detailed explanation on how to borrow a book...",
                answer2:"मैं किताब कैसे उधार ले सकता हूँ" },
        { question: "Can I access library resources off-campus?",
            question2:"मैं किताब कैसे उधार ले सकता हूँ",
             answer: "You can access library resources off-campus by...",
                answer2:"मैं किताब कैसे उधार ले सकता हूँ" },
        { question: "How do I request an inter-library loan?",
            question2:"मैं किताब कैसे उधार ले सकता हूँ",
             answer: "Inter-library loan allows you to borrow books from...",
                answer2:"मैं किताब कैसे उधार ले सकता हूँ" },
        { question: "What databases are available for engineering research?",
            question2:"मैं किताब कैसे उधार ले सकता हूँ",
             answer: "Several databases are available for engineering research including...",
                answer2:"मैं किताब कैसे उधार ले सकता हूँ" },
        { question: "Sample Question 1",
            question2:"मैं किताब कैसे उधार ले सकता हूँ",
             answer: "Sample answer for question 1." ,
                answer2:"मैं किताब कैसे उधार ले सकता हूँ"},
        { question: "Sample Question 2",
            question2:"मैं किताब कैसे उधार ले सकता हूँ",
             answer: "Sample answer for question 2." ,
                answer2:"मैं किताब कैसे उधार ले सकता हूँ"}
      ];
    
      const [openIndex, setOpenIndex] = useState(null); 
      const toggleAccordion = (index) => {
        if (openIndex === index) {
          setOpenIndex(null); 
        } else {
          setOpenIndex(index); 
        }
      };
  return (
    <div>
      <Navbar/>
      <div className='NavbarTabs'><NavbarTabs/></div>
      <div>
            <div className='bg-primarybg responsive-padding-for-all' style={{padding:'0px 50px 20px'}}>
                
                    <div className='pt-10'>
                    <p 
                            className="font-semibold text-black mx-auto w-fit" 
                            style={{ fontSize: `${headingFont}px` }} 
                            >
                            {language ? "FAQs": "पूछे जाने वाले प्रश्न" }
                        </p>
                        <p 
                            className="font-thin text-gray-500 mx-auto w-fit" 
                            style={{ fontSize: `${paraFont}px` }} 
                            >
                             {language ? "Your doubt not listed? Drop mail at ": "आपका संदेह सूचीबद्ध नहीं है? यहाँ मेल छोड़ें" }
                             <a 
                                href="https://mgcl.iitr.ac.in/" 
                                className="text-black font-semibold ml-1"
                                >
                                mgcl.iitr.ac.in
                            </a>
                        </p>
                    </div>

            </div>    
            <div className='bg-white responsive-padding-for-all' style={{padding:'20px 50px'}}>
                <div className='bg-primarybg p-5 rounded-3xl flex justify-center faq-padding-inner' >
                <div className='w-[80%] faq-width'>
                    {faqData.map((faq, index) => (
                        <div key={index}>
                        <button
                            onClick={() => toggleAccordion(index)}
                            className={`w-full flex items-center justify-between p-3 focus:outline-none transition-all 
                            ${openIndex === index ? "" : "border-b border-black"}`}
                        >
                            <span className="mr-4" style={{ fontSize: `${paraFont}px`}}>
                            {openIndex === index ? '-' : '+'}
                            </span>
                            <span className="text-left font-semibold flex-grow" style={{ fontSize: `${paraFont}px`}}>
                            {language ? faq.question: faq.question2 }
                            </span>
                        </button>

                        {openIndex === index && (
                            
                            <div 
                            style={{ fontSize: `${paraFont}px`}}
                            className="px-3 pb-3 text-left border-b border-black"
                            >
                            <span className="mr-4 font-semibold" style={{ color:'#F4F5FB' , fontSize: `${paraFont}px`}}>
                            {openIndex === index ? '-' : '+'}
                            </span>
                            {language ? faq.answer: faq.answer2 }
                            </div>
                        )}
                        </div>
                    ))}
                    </div>

                </div>
            </div>
      </div>
      <WebFooter/>
    </div>
  )
}
