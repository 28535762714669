import React, { useState } from 'react';
import NavbarTabs from '../NavbarTabs/NavbarTabs'
import Navbar from './Navbar';
import WebFooter from './WebFooter';
import { useSelector } from 'react-redux';
import arrow from "../../Assests/right-arrow.png"
export default function NewViewAllPage() {
    const navbarFont = useSelector((state) => state.navbarFontSlice);
    const paraFont = useSelector((state) => state.fontSizeSlice);
    const headingFont = useSelector((state) => state.headingFontSlice);
    const language = useSelector((state) => state.globalLanguage);
    const [selectedButton, setSelectedButton] = useState(null);

    const handleButtonClick = (buttonIndex) => {
        setSelectedButton(buttonIndex);
    };
  return (
    <div>
      <Navbar/>
      <div className='NavbarTabs'><NavbarTabs/></div>
      <div className="bg-white p-6 rounded-lg shadow-lg responsive-padding-for-all" style={{ padding: '20px 50px'}}>
        
        <h2 
          className="font-semibold text-black mb-[20px] mx-auto w-fit" 
          style={{ fontSize: `${headingFont}px` }} 
        >
          {language ? "News & Announcements" : "समाचार एवं घोषणाएँ"} 
        </h2>
        
        <div className="space-y-5 bg-primarybg p-5 new-window" style={{ borderRadius: "24px"}}>
            {[
            { time: '03:00 pm', date: 'Feb 24', branch: 'Main Branch', branch2: 'मुख्य शाखा', title: 'New Books Came In, Lelo Books' , title2: 'नई किताबें आईं, लेलो बुक्स'},
            { time: '03:00 pm', date: 'Feb 24', branch: 'Main Branch', branch2: 'मुख्य शाखा', title: 'New Books Came In, Lelo Books' , title2: 'नई किताबें आईं, लेलो बुक्स'},
            { time: '03:00 pm', date: 'Feb 24', branch: 'Main Branch', branch2: 'मुख्य शाखा', title: 'New Books Came In, Lelo Books' , title2: 'नई किताबें आईं, लेलो बुक्स'},
            { time: '03:00 pm', date: 'Feb 24', branch: 'Main Branch', branch2: 'मुख्य शाखा', title: 'New Books Came In, Lelo Books' , title2: 'नई किताबें आईं, लेलो बुक्स'},
            ].map((item, index) => (
            <div key={index} 
                  className="flex justify-between items-center bg-white news-windows p-4  shadow-sm" 
                  style={{ borderRadius: "16px"}}
                >
                  <div>
                    <p className="text-smol text-left font-bold">
                      {`${item.time} | ${item.date} | ${language ? item.branch : item.branch2}`}
                    </p>

                    <h3 className="font-bold text-left mt-[10px]" 
                      style={{ fontSize: `${paraFont}px` }} 
                    >
                      {language ? item.title : item.title2}
                    </h3>
                  </div>

                <div className="bg-navblue p-3">
                    <img src={arrow}/>
                </div>
            </div>
            ))}
        </div>
        <div className="button-group-events mt-[20px] justify-center">
            {[1, 2].map((buttonNumber, index) => (
                <button
                    key={index}
                    style={{ fontSize: `${headingFont}px` }}
                    className={`button-eventsMGCL ${selectedButton === index ? 'selected' : ''}`}
                    onClick={() => handleButtonClick(index)}
                >
                    {buttonNumber}
                </button>
            ))}
        </div>
    </div>
    <WebFooter/>
    </div>
  )
}
