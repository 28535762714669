import React from 'react'
import bannerimgg from "../../Assests/webtile2.jpg"
import { showLang } from "../../Redux/Slices/globalLanguage";
import { useDispatch, useSelector } from "react-redux";
export default function PictureBanner() {
  const navbarFont = useSelector((state) => state.navbarFontSlice);
  const paraFont = useSelector((state) => state.fontSizeSlice);
  const headingFont = useSelector((state) => state.headingFontSlice);
  const language = useSelector((state) => state.globalLanguage);
  const dispatch = useDispatch();
  const handleLanguage = (state) => {
    console.log(state)
    dispatch(showLang(state));
  };
  return (
    <div className='bg-primarybg relative servicebox-padding' style={{ padding: '50px 50px 100px'}}>
      <img src={bannerimgg} style={{ height:'500px' , width:'100%'}}/>
      <div className='bg-white absolute w-[60%] h-[200px] quote-overlay flex flex-col justify-center px-10'>
        <span className='text-[40px] font-semibold italic quote-text'>"A library is infinity under a roof"</span>
        <span className='quote-author self-end'>-Gail Carson Levine</span>
      </div>
    </div>
  )
}
