import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "../Home/LibraryMainPage.css";
import { showLang } from "../../Redux/Slices/globalLanguage";
import { useDispatch, useSelector } from "react-redux";
import serialholding from "../../Assests/Serial Holding.xlsx"
import noduesform from "../../Assests/No Dues.doc"
import EventsAtMGCL from './AboutUs/EventsAtMGCL';
export default function NavbarTabs() {
  const navigate = useNavigate();
  const language = useSelector((state) => state.globalLanguage);
  const dispatch = useDispatch();
  const handleLanguage = (state) => {
    console.log(state)
    dispatch(showLang(state));
  };
    const [isOpen, setIsOpen] = useState(false);
  
    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };
    const handleNavClick = (path) => {
      navigate(path);
    };
  return (
    <div className='w-full bg-white flex justify-between items-center px-4 py-3 nav-window'>
          <div className='nav-window-items'>
            <span
             
             style={{ fontSize:'14px'}} 
             className="cursor-pointer on-hover-nav py-2 px-3  text-black font-semibold w-fit  "
             onClick={() => handleNavClick("/")}
             >
            {language ? "Home" : "मुख्य पृष्ठ"}
            </span>
          </div>
          <div className='nav-window-items'>
            <div className="relative group">
            <span
             
             style={{ fontSize:'14px'}} 
             className="cursor-pointer on-hover-nav py-2 px-3  text-black font-semibold w-fit ">
            {language ? "e-Books" : " ई-पुस्तकें"}
            </span>
            <div 
                className="no-scrollbar absolute hidden group-hover:block bg-[#e7f3f8] shadow-md mt-1 w-max z-10 text-left" 
                style={{ 
                  fontSize: '14px', 
                  maxHeight: '200px',
                  overflowY: 'auto', 
                }}
              >
                  <a  
                    href="https://mgcl.iitr.ac.in/Docs/e-books%20guidelines.pdf" 
                    className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                    >
                    {language ? "Guidelines to access e-book": "ई-बुक तक पहुँचने के लिए दिशानिर्देश" }
                    </a>
                    <a 
                      href="https://pubs.acs.org/page/books/index.html" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "ACS eBooks ONLINE": "ACS ई-पुस्तकें ऑनलाइन" }
                      </a>
                <a  
                  href="https://www.bloomsburyvisualarts.com/" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "Bloomsburry Architecture Library": "ब्लूम्सबरी आर्किटेक्चर लाइब्रेरी" }
                    </a>
                  <a  
                    href="https://www.bloomsburyvisualarts.com/" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "Bloomsburry Design Library": "ब्लूम्सबरी डिज़ाइन लाइब्रेरी" }
                      </a>
                    <a  
                      href="https://www.eduport-global.com/" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "CBS eBooks ONLINE": "CBS ई-पुस्तकें ऑनलाइन" }
                      </a>
                    <a  
                      href="https://www.cambridge.org/core/publications/books" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "CUP eBooks ONLINE": "CUP ई-पुस्तकें ऑनलाइन" }
                      </a>
                    <a  
                      href="https://www.degruyter.com/" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "DeGruyter Press ONLINE": "डिग्रयूटर प्रेस ऑनलाइन" }
                      </a>
                    <a  
                      href="https://www.elgaronline.com/browse?level=parent&pageSize=10&sort=datedescending" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "Elgar Press eBooks ONLINE": "एल्गर प्रेस ई-पुस्तकें ऑनलाइन" }
                      </a>
                    <a  
                      href="https://www.sciencedirect.com/browse/journals-and-books?contentType=BK" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "Elsevier ScienceDirect eBooks ONLINE": "एल्सेवियर साइंसडायरेक्ट ई-पुस्तकें ऑनलाइन" }
                      </a>
                    <a  
                      href="https://www.emerald.com/insight/products" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "Emerald Business eBooks ONLINE": "एमराल्ड बिज़नेस ई-पुस्तकें ऑनलाइन" }
                      </a>
                    <a  
                      href="https://iopscience.iop.org/bookList/10/1" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "IoP eBooks ONLINE": "IoP ई-पुस्तकें ऑनलाइन" }
                      </a>
                    <a  
                      href="https://www.wileyindia.com/iitroorkee/" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "John Wiley eBooks ONLINE": "जॉन विली ई-पुस्तकें ऑनलाइन" }
                      </a>
                    <a  
                      href="https://mgcl.iitr.ac.in/" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "MGH Access Engineering ONLINE": "MGH एक्सेस इंजीनियरिंग ऑनलाइन" }
                      </a>
                    <a  
                      href="https://www.expresslibrary.mheducation.com/" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "MGH Education eBooks ONLINE": "MGH एजुकेशन ई-पुस्तकें ऑनलाइन" }
                      </a>
                    <a  
                      href="https://academic.oup.com/oxford-handbooks-online?login=false" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "OUP Handbooks (OHO) ONLINE": "OUP हैंडबुक्स (OHO) ऑनलाइन" }
                      </a>
                    <a  
                      href="https://academic.oup.com/pages/op-migration-welcome?login=false" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "OUP Scholarships (OSO) ONLINE": "OUP स्कॉलरशिप्स (OSO) ऑनलाइन" }
                      </a>
                    <a  
                      href="https://ebookcentral.proquest.com/auth/lib/iitrookee/login.action?returnURL=https%3A%2F%2Febookcentral.proquest.com%2Flib%2Fiitrookee%2Fsearch.action%3FbookStatus%3D1%26op%3Dadvance%26query%3D" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "Pearson ProQUEST eBooks ONLINE": "पियरसन प्रोक्वेस्ट ई-पुस्तकें ऑनलाइन" }
                      </a>
                    <a  
                      href="https://link.springer.com/" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "Springer Nature eBooks ONLINE": "स्प्रिंगर नेचर ई-पुस्तकें ऑनलाइन" }
                      </a>
                    <a  
                      href="https://www.taylorfrancis.com/collections" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "T&F CRCnetBase eBooks ONLINE": "T&F CRCnetBase ई-पुस्तकें ऑनलाइन" }
                      </a>
                    <a  
                      href="https://www.feynmanlectures.caltech.edu/" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "The Fynman Lectures on Physics ONLINE": "द फाइनमैन भौतिकी पर व्याख्यान ऑनलाइन" }
                      </a>
                    <a  
                      href="http://community.worldlibrary.in/?AffiliateKey=NDL-RV1151" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "The World eBook Library ONLINE": "विश्व ईबुक लाइब्रेरी ऑनलाइन" }
                      </a>
                    <a  
                      href="https://www.worldscientific.com/page/holdings/indian-inst-of-technology-roor" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "World Scientific": "विश्व वैज्ञानिक" }
                      </a>
              </div>
            </div>
          </div>
          <div className='nav-window-items'>
              <div className="relative group">
                <a
                  
                  style={{ fontSize: '14px' }} 
                  className="cursor-pointer on-hover-nav py-2 px-3 text-black font-semibold w-fit"
                >
                  {language ? "e-Journals" : " ई-पत्रिकाएँ"}
                </a>
                <div className="absolute hidden group-hover:block bg-[#e7f3f8] shadow-md mt-1 w-max z-10 text-left" style={{ fontSize:'14px'}} >
                    <a  
                        href="https://mgcl.iitr.ac.in/journals.html" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "Current Subscription of Serials": "वर्तमान धारावाहिक सदस्यता" }
                      </a>
                    <a  
                      href="https://mgcl.iitr.ac.in/pubwise.htm" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "Publisher wise e-journals": "प्रकाशक अनुसार ई-पत्रिकाएँ" }
                      </a>
                    <a  
                      href="https://mgcl.iitr.ac.in/opac/journals/journals.html" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "A-Z LIST": "ए-जेड सूची" }
                      </a>
                    <a  
                      href="https://ess.inflibnet.ac.in/eres.php?memID=177" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "e-Resources through e-Shodh Sindhu": "ई-शोध सिंधु के माध्यम से ई-संसाधन" }
                      </a>
                    <a  
                      href="https://jgatenext.com/" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "j-Gate Largest e-Journal Gateway": "जे-गेट: सबसे बड़ा ई-जर्नल गेटवे" }
                      </a>
                </div>
              </div>
            </div>
          <div className='nav-window-items'>
            <div className="relative group">
            <span
             
             style={{ fontSize:'14px'}} 
             className="cursor-pointer on-hover-nav py-2 px-3  text-black font-semibold w-fit ">
            {language ? "e-Dissert./Theses" : "ई-डिसर्टेशन/थीसिस"}
            </span>
            <div className="absolute hidden group-hover:block bg-[#e7f3f8] shadow-md mt-1 w-max z-10 text-left" style={{ fontSize:'14px'}} >
                    <a  
                      href="http://shodhbhagirathi.iitr.ac.in:8081/jspui/" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "Theses and Desertations@IITR": "थीसिस और डिज़र्टेशन @ IITR" }
                      </a>
                    <a  
                      href="https://www.proquest.com/index" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "Proquest": "प्रोक्वेस्ट" }
                      </a>
                    <a  
                      href="https://oatd.org/" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "Open Access Theses and Desertations": "ओपन एक्सेस थीसिस और डिज़र्टेशन" }
                      </a>
                    <a  
                      href="https://ndltd.org/" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "Network Digital Library of Theses & Dissertations": "थीसिस और निबंधों की नेटवर्क डिजिटल लाइब्रेरी" }
                      </a>
                </div>
            </div>
          </div>
          <div className='nav-window-items'>
            <div className="relative group">
            <span
             
             style={{ fontSize:'14px'}} 
             className="cursor-pointer on-hover-nav py-2 px-3  text-black font-semibold w-fit ">
            {language ? "e-Databases" : "ई-डेटाबेस"}
            </span>
            <div 
                className="no-scrollbar absolute hidden group-hover:block bg-[#e7f3f8] shadow-md mt-1 w-max z-10 text-left" 
                style={{ 
                  fontSize: '14px', 
                  maxHeight: '200px',
                  overflowY: 'auto',
                }}
              >
                    <a  
                      href="https://www.proquest.com/abicomplete/business/fromDatabasesLayer?accountid=27544" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "ABI/INFORM Complete": "ABI/INFORM पूर्ण" }
                      </a>
                    <a  
                      href="https://library.artstor.org/#/" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "ARTSTOR (Image Database)": "ARTSTOR (छवि डेटाबेस)" }
                      </a>
                    <a  
                      href="https://www.capitaline.com/" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "Capitaline": "कैपिटालाइन" }
                      </a>
                    <a  
                      href="http://10.41.0.29/" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "CD-ROM Databases": "CD-ROM डेटाबेस" }
                      </a>
                    <a  
                      href="https://www.engineeringvillage.com/home.url?redir=t" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "COMPENDEX (From Engineering Village)": "COMPENDEX (इंजीनियरिंग विलेज से)" }
                      </a>
                    <a  
                      href="http://www.crcnetbase.com/" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "CRCnetBase": "CRCnetBase" }
                      </a>
                    <a  
                      href="https://www.crisil.com/" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "CRIS INFAC Industrial Information (CRISIL)": "CRIS INFAC औद्योगिक जानकारी (CRISIL)" }
                      </a>
                    <a  
                      href="https://mgcl.iitr.ac.in/CRISIL%20Registration%20Steps.pdf" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "CRISIL Registration Guide": "CRISIL पंजीकरण गाइड" }
                      </a>
                    <a  
                      href="https://login.ebsco.com/?profId=ehost&requestIdentifier=5efd3d16-be16-4539-bedf-f5bd352edbd0&acrValues=uid&ui_locales&redirect_uri=https://logon.ebsco.zone/api/dispatcher/continue/prompted?state=YjFlODkwNWNkZjkzNDkwMGIxMmIzZGU5Mjk2Y2ZlMjk=&authRequest=eyJraWQiOiIxNjg2MTQ5MjEzNjMxIiwiYWxnIjoiUlMyNTYifQ.eyJpc3MiOiJodHRwczpcL1wvYXV0aC5lYnNjby56b25lXC9hcGlcL2Rpc3BhdGNoZXIiLCJhdXRoUmVxdWVzdCI6eyJsb2dpbl9oaW50IjoicHJvZmlkOmVob3N0IiwiZ3JhbnRfdHlwZSI6ImF1dGhvcml6YXRpb25fY29kZSIsInNjb3BlIjoib3BlbmlkIGVtYWlsIGFmZmlsaWF0aW9uIiwiYWNyX3ZhbHVlcyI6InVpZCIsInJlc3BvbnNlX3R5cGUiOiJjb2RlIiwicmVkaXJlY3RfdXJpIjoiaHR0cHM6XC9cL3NlYXJjaC5lYnNjb2hvc3QuY29tXC93ZWJhdXRoXC9Qcm9tcHRlZENhbGxiYWNrLmFzcHgiLCJzdGF0ZSI6IkEzWFdtejkwTmtKZzRsd3dmVlkzRFFkLXd2ZWtkdW1JZUk0Q056enhXcEJBT0hUcmNvWXBnYzQxcW5jYzNrT09haGdlem1YY2FUWXZvaktiVk81azduaDFjbzMtSzhldm9GaGVtd2dITUl4UklJY2VKYVhKSUY5a3E5eHk5TDRVQTQ0elJ1dDh4Wk5pS0JwRlBEbU1uMlFfQm5UV091XzFUQWZQTEhjZUY0QWhES0MyUFFVRkdXdm9iQ2lYam11VlFTT2d3WUgwYjlNc2NQSk5ydEhvc1BxUzBQeTE5alNrNnpEYWtKQl9sWWZ2dmV5OHp3dTA3anFjd3NtM201NGZaWUxyYTdRdENEeDhOWHdtRUhNWnh6MTdqWjBMcXJuZUZEaWlLQzBZSS14SFdVQ21odHp3VGpzSVJOTjE2TUI4UWxMZnNSNHQxMl83U3NkREs1Um8zLXV1YWdZbVhHcHRhOE1LaUFlRVYtbmVodVNMWFd3cWVYc2hDRE0xZXVpQUNjSjFrQmhHIiwiY2xpZW50X2lkIjoiYXdneWNJeDU3TXJ3bkRRNWg0VWU2eUNWRVAwcjVNdDkiLCJyZXNwb25zZV9tb2RlIjoicXVlcnkiLCJyZXFJZCI6IjVlZmQzZDE2LWJlMTYtNDUzOS1iZWRmLWY1YmQzNTJlZGJkMCJ9LCJpYXQiOjE3MjgzOTA0NjYsImp0aSI6IjMxYTRmMWQyLWJhZDYtNDc1OC1iMTg4LTIyYjdjZDQzNTIxMCIsInJlcUlkIjoiNWVmZDNkMTYtYmUxNi00NTM5LWJlZGYtZjViZDM1MmVkYmQwIn0.YkPmUVCxPeJbpdCAR1h993m4OqB_7R1AK6iosCOcGtr63XRqAcMWHfeey64WdcFZaiM16kOU7pvX5KmFg6SObzKZX0zD2tVMmty55701fVJP0_c2pwcdeA1JadXseX1Orfx-BumfvQiPqXswK4JbkhIwULJBUKOhUJtK9E3LTb_eBU9oMKJzYu72pets-WFLf_7ZWCmWpPabxMOuJhhLjv-sbkFZr8qA_w0NZb8JiMWKbaVIh7jS7P1IZu38I6YuKMrivyuBaS5uOCofuRb9AapeceOZxVXyEHPENKIKjo7ez6JBR8rkRg3lG1wgs9ku-C0iJNUGcd1pJRFpnsIxTg" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "EBSCO EconLite With Full Text": "EBSCO EconLite पूर्ण पाठ के साथ" }
                      </a>
                    <a  
                      href="https://login.ebsco.com/?profId=ehost&requestIdentifier=5efd3d16-be16-4539-bedf-f5bd352edbd0&acrValues=uid&ui_locales&redirect_uri=https://logon.ebsco.zone/api/dispatcher/continue/prompted?state=NWI0OTFiOWQxNWJmNGMxYWE0NTIzMzU5NGQyMjEwYTk=&authRequest=eyJraWQiOiIxNjg2MTQ5MjEzNjMxIiwiYWxnIjoiUlMyNTYifQ.eyJpc3MiOiJodHRwczpcL1wvYXV0aC5lYnNjby56b25lXC9hcGlcL2Rpc3BhdGNoZXIiLCJhdXRoUmVxdWVzdCI6eyJsb2dpbl9oaW50IjoicHJvZmlkOmVob3N0IiwiZ3JhbnRfdHlwZSI6ImF1dGhvcml6YXRpb25fY29kZSIsInNjb3BlIjoib3BlbmlkIGVtYWlsIGFmZmlsaWF0aW9uIiwiYWNyX3ZhbHVlcyI6InVpZCIsInJlc3BvbnNlX3R5cGUiOiJjb2RlIiwicmVkaXJlY3RfdXJpIjoiaHR0cHM6XC9cL3NlYXJjaC5lYnNjb2hvc3QuY29tXC93ZWJhdXRoXC9Qcm9tcHRlZENhbGxiYWNrLmFzcHgiLCJzdGF0ZSI6IkEzV09rMXY2NUQ4LVVEbVVuZ2dTalBnczZLMDBYT21JZUk0Q056enhXcEJBT0hUcmNvWXBnYzQxcW5jYzNrT09haGdPNWdwcjUtWGlyLVVkY3VWRXhNNGZ1cGVzMEp1UzcweDR6T1ZOYmJLUW9LelZHbnZJamVNWmlBU0FPWTdBazVBWmY2dFVBWmZZZmZNa1dCOFczazVaM0hjeGgzcXFwNExnQUdZSHoyUklneVJrenJZNFVXb1dpT1JXRXFUNXNxXzZCTV9JYjFtVGNqbE41XzZCaGFPZlJ2Tjcta2JrSFBUVDRmcnd3bWhEWWtPZy05Sm9KVmd2OUdldS0tR0d1SHB3SnYySFo0b2ZHMUZtQlRlNW9FUUVaMmQ3a1N4cDBMRlNPdzdBdjN5cVRtOVlaWTd4akpRbW5QY1F3dENwNlJTdlgyNFd5dS1TZFFnNHlReGstTzZsVmhIb2N1WGxQcFpoT1RjbWlQNnNmNHhqeUN6MlFQSGhnUWZXMjB5eWl0Z0cxWVhRQWciLCJjbGllbnRfaWQiOiJhd2d5Y0l4NTdNcnduRFE1aDRVZTZ5Q1ZFUDByNU10OSIsInJlc3BvbnNlX21vZGUiOiJxdWVyeSIsInJlcUlkIjoiNWVmZDNkMTYtYmUxNi00NTM5LWJlZGYtZjViZDM1MmVkYmQwIn0sImlhdCI6MTcyODM5MDQ5MSwianRpIjoiNjg3YjdlZDAtY2E0Ny00ZWRiLTlmMzgtZmRmMmZiNmViYWIwIiwicmVxSWQiOiI1ZWZkM2QxNi1iZTE2LTQ1MzktYmVkZi1mNWJkMzUyZWRiZDAifQ.OcjmPM5wjHsfrdkGXyfRq9IqlMKr9Ua7152_1rkmbmBpNsbpe2Y1q4vYLq6yiJdoyahbGQPLnmF9UlUM715PZvtefJHv5HCwLo1Au9qG2DnbJCQERVbmG2cEW3mKp6KArUHDxw-KQhFVG6haAGAbsxqDz3AsBnlFx8nRAC94Ake_E4IiAeu81OXRt8_KTljw0hldChiNQF4f031tjqypJpo5J1gu6uz51B9jOHtfXeXdcSE0AXMg-9-NC-_JKr6KvaOH6CsJT0FMUg0Hki2AKlQr54uEFoU3GDhGoDxvgmwhy7GElzR5HB4ecexMEc1msobk2GoTBjwsADceaB-kfQ" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "EBSCO host - Business Source Ultimate": "EBSCO होस्ट - बिज़नेस सोर्स अल्टिमेट" }
                      </a>
                    <a  
                      href="https://www.euromonitor.com/our-expertise/passport" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "Euromonitor - Passport": "यूरोमॉनिटर - पासपोर्ट" }
                      </a>
                    <a  
                      href="https://ieeexplore.ieee.org/Xplore/home.jsp" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "IEEE Xplore": "IEEE Xplore" }
                      </a>
                    <a  
                      href="https://incites.clarivate.com/#/landing" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "InCites": "InCites" }
                      </a>
                    <a  
                      href="https://www.indiastat.com/" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "IndiaStat": "इंडियास्टैट" }
                      </a>
                    <a  
                      href="https://www.primeinfobase.com/index.aspx" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "Indian Boards Database": "भारतीय बोर्ड डेटाबेस" }
                      </a>
                    <a  
                      href="https://www.engineeringvillage.com/home.url?redir=t" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "INSPEC (From Engineering Village)": "INSPEC (इंजीनियरिंग विलेज से)" }
                      </a>
                    <a  
                      href="https://isid.org.in/" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "Institute for Studies in Industrial Development (ISID) Database": "औद्योगिक विकास अध्ययन संस्थान (ISID) डेटाबेस" }
                      </a>
                    <a  
                      href="https://jgatenext.com/search" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "JGate Plus (JCCC)": "JGate प्लस (JCCC)" }
                      </a>
                    <a  
                      href="https://www.jove.com/" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "Journal of Visual Experiments (JoVE)": "जर्नल ऑफ विज़ुअल एक्सपेरिमेंट्स (JoVE)" }
                      </a>
                    <a  
                      href="https://signin.lexisnexis.com/lnaccess/mip/authn?aci=in&identityprofileid=6PRSBV58099&request_id=1" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "Lexis Advance": "लेक्सिस एडवांस" }
                      </a>
                    <a  
                      href="https://onepetro.org/" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "OnePetro": "वनपेट्रो" }
                      </a>
                    <a  
                      href="https://www.engineeringvillage.com/home.url" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "PaperChem (From Engineering Village)": "पेपरकेम (इंजीनियरिंग विलेज से)" }
                      </a>
                    <a  
                      href="https://psycnet.apa.org/search" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "PsycARTICLES": "PsycARTICLES" }
                      </a>
                    <a  
                      href="https://mathscinet.ams.org/mathscinet/publications-search" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "MathSciNet": "MathSciNet" }
                      </a>
                    <a  
                      href="https://mgcl.iitr.ac.in/scifinder.html" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "Sci Finder Scholar (Chemical Abstract Services)": "Sci Finder Scholar (केमिकल एब्सट्रैक्ट सर्विसेज)" }
                      </a>
                    <a  
                      href="https://www.scopus.com/home.url" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "Scopus": "स्कोपस" }
                      </a>
                    <a  
                      href="https://www.spiedigitallibrary.org/?SSO=1#_=_" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "SPIE Digital Library": "SPIE डिजिटल लाइब्रेरी" }
                      </a>
                    <a  
                      href="https://www.arabidopsis.org/" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "The Arabidopsis Information Resources (TAIR)": "द अरबीडॉप्सिस इंफॉर्मेशन रिसोर्सेज (TAIR)" }
                      </a>
                    <a  
                      href="https://access.clarivate.com/login?app=wos&alternative=true&shibShireURL=https:%2F%2Fwww.webofknowledge.com%2F%3Fauth%3DShibboleth&shibReturnURL=https:%2F%2Fwww.webofknowledge.com%2F&roaming=true" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "Web of Science": "वेब ऑफ साइंस" }
                      </a>
              </div>
            </div>
          </div>
          <div className='nav-window-items'>
            <div className="relative group">
            <span
             
             style={{ fontSize:'14px'}} 
             className="cursor-pointer on-hover-nav py-2 px-3  text-black font-semibold w-fit ">
            {language ? "e-Standards" : "ई-मानक"}
            </span>
              <div className="absolute hidden group-hover:block bg-[#e7f3f8] shadow-md mt-1 w-max z-10 text-left" style={{ fontSize:'14px'}} >
                    <a  
                      href="https://compass.astm.org/" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "ASTM Standards": "ASTM मानक" }
                      </a>
                    <a  
                      href="https://standards.bsb.co.in/BSBLogin.aspx" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "Indian Standards": "भारतीय मानक" }
                      </a>
                    <a  
                      href="https://standards.bsbedge.com/" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "IEC Standards": "IEC मानक" }
                      </a>
                    <a  
                      href="http://192.168.105.134/bs_eurocode/index.html" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "Eurocodes": "यूरोकोड्स" }
                      </a>
                    <a  
                      href="https://standards.bsbedge.com/default.aspx" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "British Standards": "ब्रिटिश मानक" }
                      </a>
              </div>
            </div>
          </div>
          <div className='nav-window-items'>
            <div className="relative group">
            <span
             
             style={{ fontSize:'14px'}} 
             className="cursor-pointer on-hover-nav py-2 px-3  text-black font-semibold w-fit ">
            {language ? "e-Repository" : "ई-रिपोजिटरी "}
            </span>
            <div className="absolute hidden group-hover:block bg-[#e7f3f8] shadow-md mt-1 w-max z-10 text-left" style={{ fontSize:'14px'}} >
                    <a  
                      href="http://10.41.0.25:8080/xmlui/" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "Bhagirathi (Archival Documents)": "भागीरथी (अर्काइव दस्तावेज़)" }
                      </a>
                    <a  
                      href="http://shodhbhagirathi.iitr.ac.in:8081/jspui/" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "Shodh Bhagirathi (Theses/Dissertations of IITR)": "शोध भागीरथी (IITR के थीसिस/डिज़र्टेशन)" }
                      </a>
            </div>
            </div>
          </div>
          <div className='nav-window-items'>
            <div className="relative group">
            <span
             
             style={{ fontSize:'14px'}} 
             className="cursor-pointer on-hover-nav py-2 px-3  text-black font-semibold w-fit ">
            {language ? "e-Newspaper" : "ई-अख़बार"}
            </span>
            <div className="absolute hidden group-hover:block bg-[#e7f3f8] shadow-md mt-1 w-max z-10 text-left" style={{ fontSize:'14px'}} >
                    <a  
                       href="https://www.pressreader.com/" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "Press Reader": "प्रेस रीडर" }
                      </a>
                    <a  
                      href="https://mgcl.iitr.ac.in/The%20Wall%20Street%20Journal.htm" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "The Wall Street Journal": "द वॉल स्ट्रीट जर्नल" }
                      </a>
                    <a  
                      href="https://mgcl.iitr.ac.in/Financial%20Times.htm" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "FINANCIAL TIMES": "फाइनेंशियल टाइम्स" }
                      </a>
            </div>
            </div>
          </div>
          {/* <div className='nav-window-items'>
            <div className="relative group">
            <span
             
             style={{ fontSize:'14px'}} 
             className="cursor-pointer on-hover-nav py-2 px-3  text-black font-semibold w-fit ">
            {language ? "Print Journals" : "पत्रिकाएं छापें"}
            </span>
            <div className="absolute hidden group-hover:block bg-[#e7f3f8] shadow-md mt-1 w-max z-10 text-left" style={{ fontSize:'14px'}} >
                    <a  
                      href="https://mgcl.iitr.ac.in/journals.html" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "Current Subscription of Serials": "वर्तमान धारावाहिक सदस्यता" }
                      </a>
                    <a  
                      href={serialholding}  
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "Serial Holdings": "धारावाहिक धारणाएँ" }
                      </a>
            </div>
            </div>
          </div> */}
          <div className='nav-window-items'>
            <div className="relative group">
            <span
             
             style={{ fontSize:'14px'}} 
             className="cursor-pointer on-hover-nav py-2 px-3  text-black font-semibold w-fit ">
            {language ? "Open Sources" : "ओपन सोर्स"}
            </span>
            <div 
                className="no-scrollbar absolute hidden group-hover:block bg-[#e7f3f8] shadow-md mt-1 w-80 z-10 text-left" 
                style={{ 
                  fontSize: '14px', 
                  maxHeight: '200px',
                  overflowY: 'auto',
                }}
              >
                    <a  
                      href="https://www.biomedcentral.com/" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "BioMed Central": "बायोमेड सेंटरल" }
                      </a>
                    <a  
                      href="https://www.digimat.in/#electrical-engineering" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "DIGIMAT - The Video Learning Platform": "DIGIMAT - वीडियो लर्निंग प्लेटफॉर्म" }
                      </a>
                    <a  
                      href="https://doabooks.org/" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "Directory of Open Access Books": "ओपन एक्सेस बुक्स की निर्देशिका" }
                      </a>
                    <a  
                      href="https://doaj.org/" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "Directory of Open Access Journals": "ओपन एक्सेस जर्नल्स की निर्देशिका" }
                      </a>
                    <a  
                      href="http://www.opendoar.org/" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "Directory of Open Access Repository": "ओपन एक्सेस रिपॉजिटरी की निर्देशिका" }
                      </a>
                    <a  
                      href="https://www.employmentnews.gov.in/NewEmp/Home.aspx" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "e-Employment News": "ई-नौकरी समाचार" }
                      </a>
                    <a  
                      href="https://www.magzter.com/top-free-magazines" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "e-Magazines": "ई-मैगज़ीन" }
                      </a>
                    <a  
                      href="https://www.epapergallery.com/index.htm" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "e-Paper Gallery": "ई-पेपर गैलरी" }
                      </a>
                    <a  
                      href="http://yojana.gov.in/" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "e-Yojana Magazine": "ई-योजना मैगज़ीन" }
                      </a>
                    <a  
                      href="https://www.gandhiheritageportal.org/" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "Gandhi Heritage": "गांधी विरासत" }
                      </a>
                    <a  
                      href="https://www.intechopen.com/" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "Intech Open Science/ Open Mind": "इंटेक ओपन साइंस/ओपन माइंड" }
                      </a>
                    <a  
                      href="https://archive.org/" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "Internet Archive: Digital Library of Free Books, Movies, Music & Wayback Machine": "इंटरनेट आर्काइव: मुफ्त पुस्तकों, फ़िल्मों, संगीत और वेब मशीन का डिजिटल लाइब्रेरी" }
                      </a>
                    <a  
                      href="https://ndltd.org/" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "Network Digital Library of Theses & Dissertations": "थीसिस और शोध प्रबंध की नेटवर्क डिजिटल लाइब्रेरी" }
                      </a>
                    <a  
                      href="https://nptel.ac.in/" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "NPTEL": "NPTEL" }
                      </a>
                    <a  
                      href="https://mgcl.iitr.ac.in/NPTEL-1308-video-courses.pdf" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "NPTEL Video Courses: 1308": "NPTEL वीडियो पाठ्यक्रम: 1308" }
                      </a>
                    <a  
                      href="https://mgcl.iitr.ac.in/49200-nptel-video-lecture-topics.pdf" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "NPTEL Video Lectures: 49500+": "NPTEL वीडियो लेक्चर: 49500+" }
                      </a>
                    <a  
                      href="https://www.oapen.org/home" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "Open Access Books OAPEN": "ओपन एक्सेस पुस्तकें OAPEN" }
                      </a>
                    <a  
                      href="https://www.oalib.com/" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "Open Access Library": "ओपन एक्सेस लाइब्रेरी" }
                      </a>
                    <a  
                      href="https://oatd.org/" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "Open Access Theses and Dissertations": "ओपन एक्सेस थीसिस और डिज़र्टेशन" }
                      </a>
                    <a  
                      href="https://www.gutenberg.org/" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "Project Gutenberg - Free e-Books": "प्रोजेक्ट गुटेनबर्ग - मुफ्त ई-पुस्तकें" }
                      </a>
                    <a  
                      href="https://www.ncbi.nlm.nih.gov/pmc/" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "PubMed Central": "पबमेड सेंटरल" }
                      </a>
                    <a  
                      href="http://www.rarebookroom.org/" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "Rare Books": "दुर्लभ किताबें" }
                      </a>
                    <a  
                      href="https://vedicheritage.gov.in/" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "Vedic Heritage": "वैदिक विरासत" }
                      </a>
                    <a  
                      href="https://www.vivekananda.net/Library.html" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "Swami Vivekananda Library": "स्वामी विवेकानंद लाइब्रेरी" }
                      </a>
                    <a  
                      href="https://openknowledge.worldbank.org/" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "The World Bank Open Knowledge Repository": "विश्व बैंक ओपन नॉलेज रिपॉजिटरी" }
                      </a>
                    <a  
                      href="https://dp.la/" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "US Digital Public Library": "US डिजिटल पब्लिक लाइब्रेरी" }
                      </a>
                    <a  
                      href="https://vlib.org/" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "WWW Virtual Library": "WWW वर्चुअल लाइब्रेरी" }
                      </a>
            </div>
            </div>
          </div>
          <div className='nav-window-items'>
            <div className="relative group">
            <span
             
             style={{ fontSize:'14px'}} 
             className="cursor-pointer on-hover-nav py-2 px-3  text-black font-semibold w-fit ">
            {language ? "About Us" : "हमारे बारे में"}
            </span>
            <div 
                className="no-scrollbar absolute hidden group-hover:block right-0 bg-[#e7f3f8] shadow-md mt-1 w-max z-10 text-left" 
                style={{ 
                  fontSize: '14px', 
                  maxHeight: '200px',
                  overflowY: 'auto',
                }}
              >
                    <a  
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      onClick={() => handleNavClick("/about-us-history")}
                      >
                      {language ? " History": "इतिहास" }
                      </a>
                    <a  
                      href="https://doaj.org/" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "Vision & Mission": "दृष्टि और मिशन" }
                      </a>
                    <a  
                      href="https://mgcl.iitr.ac.in/Docs/qp.pdf" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "Quality Policy & Objectives": "गुणवत्ता नीति और उद्देश्य" }
                      </a>
                    <a   
                    
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      onClick={() => handleNavClick("/about-us-ourTeam")}
                      >
                      {language ? "Our Team": "हमारी टीम" }
                      
                      </a>
                    <a  
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      onClick={() => handleNavClick("/about-us-collections")}
                      >
                      {language ? " Collections": "संग्रह" }
                      </a>
                    <a  
                      href="https://mgcl.iitr.ac.in/services/1.html" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "Services": "सेवाएँ" }
                      </a>
                    <a  
                      href="https://mgcl.iitr.ac.in/Docs/fp.pdf" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "Floor Plan": "फ्लोर प्लान" }
                      </a>
                    <a   
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      onClick={() => handleNavClick("/about-us-holidays")}
                      >
                      {language ? "Library Hours & Holidays": "लाइब्रेरी का समय और छुट्टियाँ" }
                      </a>
                    <a  
                      href="https://mgcl.iitr.ac.in/guide.html" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "User's Guide": "उपयोगकर्ता मार्गदर्शिका" }
                      </a>
                    <a  
                      onClick={() => handleNavClick("/events")}
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "Events at MGCL": "MGCL में कार्यक्रम" }
                      </a>
                    <a  
                      href="https://mgcl.iitr.ac.in/Docs/mgcl%20at%20a%20glane.pdf" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "MGCL at a Glance": "MGCL एक नज़र में" }
                      </a>
            </div>
            </div>
          </div>
          <div className='nav-window-items'>
            <div className="relative group">
            <span
             
             style={{ fontSize:'14px'}} 
             className="cursor-pointer on-hover-nav py-2 px-3  text-black font-semibold w-fit ">
            {language ? "Download" : "डाउनलोड"}
            </span>
            <div className="absolute hidden group-hover:block bg-[#e7f3f8] right-0 shadow-md mt-1 w-max z-10 text-left" style={{ fontSize:'14px'}} >
                    <a
                      href="https://mgcl.iitr.ac.in/Docs/Vendor%20Empanelment%20Condition.pdf" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "Vendor Empanelment Condition (Journals)": "विक्रेता पैनलमेंट शर्तें (पत्रिकाएँ)" }
                      </a>
                    <a
                      href="https://mgcl.iitr.ac.in/CORPORATE%20MEMBERSHIP%20.pdf" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "Form for Corporate Membership": "कॉर्पोरेट सदस्यता के लिए फॉर्म" }
                      </a>
                    <a
                      href="https://mgcl.iitr.ac.in/Membership%20form%20for%20Faculty%20and%20Staff.pdf" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "Membership Form for Faculty and Staff": "फैकल्टी और स्टाफ के लिए सदस्यता फॉर्म" }
                      </a>
                    <a
                      href="https://mgcl.iitr.ac.in/RETIRED.pdf" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "Membership Form for Retired Faculty/Staff": "सेवानिवृत्त फैकल्टी/स्टाफ के लिए सदस्यता फॉर्म" }
                      </a>
                    <a
                      href={noduesform} 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "No Dues Form": "नो ड्यूज़ फॉर्म" }
                      </a>
                    <a
                      href="https://mgcl.iitr.ac.in/Security%20refund%20form.pdf" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="cursor-pointer block py-2 px-4 hover:bg-[#a6cfe0]"
                      >
                      {language ? "Security Refund Form": "सुरक्षा रिफंड फॉर्म" }
                      </a>
            </div>
            </div>
          </div>
     
    </div>
  )
}