import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import NavbarTabs from "../NavbarTabs"
import Navbar from "../../Home/Navbar"
import WebFooter from "../../Home/WebFooter"
import image from "../../../Assests/frnds2.jpeg"
import "./AboutUs.css"
export default function EventsAtMGCL() {
    const navbarFont = useSelector((state) => state.navbarFontSlice);
    const paraFont = useSelector((state) => state.fontSizeSlice);
    const headingFont = useSelector((state) => state.headingFontSlice);
    const language = useSelector((state) => state.globalLanguage);
    const [selectedButton, setSelectedButton] = useState(null);

    const handleButtonClick = (buttonIndex) => {
        setSelectedButton(buttonIndex);
    };
    const imageData = [
        {
          title: 'Drawing Competition 2016',
          links: [
                { name: 'Prize Distribution', url: '/prize-distribution' },
                { name: 'Photos', url: '/photos' },
                { name: 'Winner List', url: '/winner-list' }
            ],
          imgSrc: image,
          hasDetails: true,
        },
        {
            title: 'Drawing Competition 2016',
            links: [
                { name: 'Prize Distribution', url: '/prize-distribution' },
                { name: 'Photos', url: '/photos' },
                { name: 'Winner List', url: '/winner-list' }
            ],
            imgSrc: image,
            hasDetails: true,
          },
          {
            title: 'Drawing Competition 2016',
            links: [
                { name: 'Prize Distribution', url: '/prize-distribution' },
                { name: 'Photos', url: '/photos' },
                { name: 'Winner List', url: '/winner-list' }
            ],
            imgSrc: image,
            hasDetails: true,
          },
          {
            title: 'Drawing Competition 2016',
            links: [
                { name: 'Prize Distribution', url: '/prize-distribution' },
                { name: 'Photos', url: '/photos' },
                { name: 'Winner List', url: '/winner-list' }
            ],
            imgSrc: image,
            hasDetails: true,
          },
          {
            title: 'Drawing Competition 2016',
            links: [
                { name: 'Prize Distribution', url: '/prize-distribution' },
                { name: 'Photos', url: '/photos' },
                { name: 'Winner List', url: '/winner-list' }
            ],
            imgSrc: image,
            hasDetails: true,
          },
          {
            title: 'Drawing Competition 2016',
            links: [
                { name: 'Prize Distribution', url: '/prize-distribution' },
                { name: 'Photos', url: '/photos' },
                { name: 'Winner List', url: '/winner-list' }
            ],
            imgSrc: image,
            hasDetails: true,
          },
          {
            title: 'Drawing Competition 2016',
            links: [
                { name: 'Prize Distribution', url: '/prize-distribution' },
                { name: 'Photos', url: '/photos' },
                { name: 'Winner List', url: '/winner-list' }
            ],
            imgSrc: image,
            hasDetails: true,
          },
          {
            title: 'Drawing Competition 2016',
            links: [
                { name: 'Prize Distribution', url: '/prize-distribution' },
                { name: 'Photos', url: '/photos' },
                { name: 'Winner List', url: '/winner-list' }
            ],
            imgSrc: image,
            hasDetails: true,
          },
          {
            title: 'Drawing Competition 2016',
            links: [
                { name: 'Prize Distribution', url: '/prize-distribution' },
                { name: 'Photos', url: '/photos' },
                { name: 'Winner List', url: '/winner-list' }
            ],
            imgSrc: image,
            hasDetails: true,
          },
      ];
  return (
    <div>
      <Navbar/>
      <div className='NavbarTabs'><NavbarTabs/></div>
      <div className='bg-primarybg responsive-padding-for-all' style={{padding:'20px 50px'}}>
      <span 
                className="font-semibold text-black mb-[20px] mx-auto w-fit" 
                style={{ fontSize: `${headingFont}px` }} 
                >
                {language ? "Events at MGCL": "MGCL में कार्यक्रम" }
            </span>
            <div className='grid grid-cols-3 gap-y-12' style={{padding:'50px 0px'}}>
                {imageData.map((item, index) => (
                <div key={index} className="image-container-events flex justify-center items-center">
                    <div className='image-events w-[85%] rounded-3xl overflow-hidden cursor-pointer relative  hover:scale-105'>
                    {/* Image */}
                    <img className=" image-events2" src={item.imgSrc}/>
                    
                    {/* Overlay */}
                    <div className="overlay-events " style={{ fontSize: `${paraFont}px` }}>
                         <a className='font-bold'>{item.title}</a>
                         <div className='flex justify-between w-full'>
                            {item.links.map((link, index) => (
                            <a className='underline font-thin' key={index} href={link.url}>{link.name}</a>
                            ))}
                         </div>
                        </div>
                    </div>
                </div>
                ))}
            </div>
            <div className="button-group-events justify-center">
            {[1, 2].map((buttonNumber, index) => (
                <button
                    key={index}
                    style={{ fontSize: `${headingFont}px` }}
                    className={`button-eventsMGCL ${selectedButton === index ? 'selected' : ''}`}
                    onClick={() => handleButtonClick(index)}
                >
                    {buttonNumber}
                </button>
            ))}
        </div>
      </div>
      <WebFooter/>
    </div>
  )
}
