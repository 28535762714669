import React from 'react'
import { useState } from 'react';
import "./AboutUs.css"
import NavbarTabs from "../NavbarTabs"
import Navbar from "../../Home/Navbar"
import WebFooter from "../../Home/WebFooter"
import { useSelector } from 'react-redux';
import libimg from "../../../Assests/iitrlib-img.jpeg"
export default function Collections() {
    const navbarFont = useSelector((state) => state.navbarFontSlice);
    const paraFont = useSelector((state) => state.fontSizeSlice);
    const headingFont = useSelector((state) => state.headingFontSlice);
    const language = useSelector((state) => state.globalLanguage);
    const faqData = [
        { question: "How do I borrow a book?",
            question2:"मैं किताब कैसे उधार ले सकता हूँ?",
             answer: "Here is the detailed explanation on how to borrow a book...",
                answer2:"मैं किताब कैसे उधार ले सकता हूँ" },
        { question: "Can I access library resources off-campus?",
            question2:"मैं किताब कैसे उधार ले सकता हूँ",
             answer: "You can access library resources off-campus by...",
                answer2:"मैं किताब कैसे उधार ले सकता हूँ" },
        { question: "How do I request an inter-library loan?",
            question2:"मैं किताब कैसे उधार ले सकता हूँ",
             answer: "Inter-library loan allows you to borrow books from...",
                answer2:"मैं किताब कैसे उधार ले सकता हूँ" },
        { question: "What databases are available for engineering research?",
            question2:"मैं किताब कैसे उधार ले सकता हूँ",
             answer: "Several databases are available for engineering research including...",
                answer2:"मैं किताब कैसे उधार ले सकता हूँ" },
        { question: "Sample Question 1",
            question2:"मैं किताब कैसे उधार ले सकता हूँ",
             answer: "Sample answer for question 1." ,
                answer2:"मैं किताब कैसे उधार ले सकता हूँ"},
        { question: "Sample Question 2",
            question2:"मैं किताब कैसे उधार ले सकता हूँ",
             answer: "Sample answer for question 2." ,
                answer2:"मैं किताब कैसे उधार ले सकता हूँ"}
      ];
    
      const [openIndex, setOpenIndex] = useState(null); 
      const toggleAccordion = (index) => {
        if (openIndex === index) {
          setOpenIndex(null); 
        } else {
          setOpenIndex(index); 
        }
      };
  return (
    <div>
      <Navbar/>
      <div className='NavbarTabs'><NavbarTabs/></div>
      <div>
      <div className='bg-primarybg py-3'>
        <div className='p-[10px]'>
        <span 
                className="font-semibold text-black mb-[20px] mx-auto w-fit" 
                style={{ fontSize: `${headingFont}px` }} 
                >
                {language ? "Collections and Resources": "संग्रह और संसाधन" }
            </span>
            </div>
        </div>
           
            <div className='bg-primarybg responsive-padding-for-all' style={{padding:'0px 50px 20px'}}>
                <div className='bg-white p-3 rounded-3xl inner-responsive-padding'>
                <img style={{width:'100%' ,borderRadius:'16px' , marginBottom:'30px' , aspectRatio:'1/0.4'}} 
                    src={libimg}
                    />
                    <p className='p-3 text-justify font-semibold' style={{ fontSize: `${paraFont}px` }}>
                        {language 
                            ? "The Mahatma Gandhi Central Library (MGCL) at IIT Roorkee serves as a comprehensive resource for students, researchers, and faculty members, offering an extensive range of materials to foster learning and innovation. As one of the most well-equipped libraries in India, we are committed to providing world-class academic support."
                            : "आईआईटी रुड़की की महात्मा गांधी केंद्रीय पुस्तकालय (MGCL) छात्रों, शोधकर्ताओं और फैकल्टी सदस्यों के लिए एक समग्र संसाधन के रूप में कार्य करता है, जो सीखने और नवाचार को बढ़ावा देने के लिए सामग्रियों की एक विस्तृत श्रृंखला प्रदान करता है। भारत के सबसे सुसज्जित पुस्तकालयों में से एक के रूप में, हम विश्व स्तरीय शैक्षणिक समर्थन प्रदान करने के लिए प्रतिबद्ध हैं।"
                        }
                    </p>

                    <p className='p-3 text-justify font-semibold' style={{ fontSize: `${paraFont}px` }}>
                        {language 
                            ? "The library is home to more than 4 lakh (400,000) documents, comprising print and electronic resources. This includes over 2 lakh books, 60,000+ textbooks, 50,000 journal volumes, and 2 million theses and dissertations. Our specialized e-resources include databases like ProQuest, Springer, and Elsevier, offering more than 35,000 e-books and access to journals from world-renowned societies like IEEE, ASME, and ACS."
                            : "पुस्तकालय में 4 लाख (400,000) से अधिक दस्तावेज़ हैं, जिसमें प्रिंट और इलेक्ट्रॉनिक संसाधन शामिल हैं। इसमें 2 लाख से अधिक पुस्तकें, 60,000+ पाठ्यपुस्तकें, 50,000 पत्रिका खंड, और 20 लाख शोध प्रबंध और थीसिस शामिल हैं। हमारे विशेष ई-संसाधनों में प्रोक्वेस्ट, स्प्रिंगर, और एल्सेवियर जैसे डेटाबेस शामिल हैं, जो 35,000 से अधिक ई-बुक्स और विश्व-प्रसिद्ध समाजों जैसे IEEE, ASME और ACS से पत्रिकाओं की पहुँच प्रदान करते हैं।"
                        }
                    </p>
                    <p className='p-3 text-justify font-semibold' style={{ fontSize: `${paraFont}px` }}>
                        {language 
                            ? "At MGCL, we recognize the importance of digital access in modern academic research. Our online library offers seamless access to a vast collection of e-books, e-journals, patents, and archival material. Our library offers personalized research assistance, providing one-on-one consultations to help you access the most relevant resources for your projects."
                            : "MGCL में, हम आधुनिक शैक्षणिक अनुसंधान में डिजिटल पहुँच के महत्व को पहचानते हैं। हमारी ऑनलाइन पुस्तकालय एक विशाल संग्रह तक निर्बाध पहुँच प्रदान करता है, जिसमें ई-बुक्स, ई-जर्नल्स, पेटेंट, और अभिलेखीय सामग्री शामिल हैं। हमारी पुस्तकालय व्यक्तिगत अनुसंधान सहायता प्रदान करती है, जो आपको अपने परियोजनाओं के लिए सबसे प्रासंगिक संसाधनों तक पहुँचने में मदद करने के लिए एक-पर-एक परामर्श प्रदान करती है।"
                        }
                    </p>
                    <p className='p-3 text-justify font-semibold' style={{ fontSize: `${paraFont}px` }}>
                        {language 
                            ? "We offer membership programs to students, faculty, and researchers from partner institutions, granting access to our full range of physical and digital resources."
                            : "हम साझेदार संस्थानों के छात्रों, फैकल्टी और शोधकर्ताओं के लिए सदस्यता कार्यक्रम प्रदान करते हैं, जो हमारे भौतिक और डिजिटल संसाधनों की पूरी श्रृंखला तक पहुँच प्रदान करते हैं।"
                        }
                    </p>
                </div>
                    <div className='pt-14'>
                    <p 
                            className="font-semibold text-black mx-auto w-fit" 
                            style={{ fontSize: `${headingFont}px` }} 
                            >
                            {language ? "FAQs": "पूछे जाने वाले प्रश्न" }
                        </p>
                        <p 
                            className="font-thin text-gray-500 mx-auto w-fit" 
                            style={{ fontSize: `${paraFont}px` }} 
                            >
                             {language ? "Your doubt not listed? Drop mail at ": "आपका संदेह सूचीबद्ध नहीं है? यहाँ मेल छोड़ें" }
                             <a 
                                href="https://mgcl.iitr.ac.in/" 
                                className="text-black font-semibold ml-1"
                                >
                                mgcl.iitr.ac.in
                            </a>
                        </p>
                    </div>

            </div>    
            <div className='bg-white responsive-padding-for-all' style={{padding:'20px 50px'}}>
                <div className='bg-primarybg p-5 rounded-3xl flex justify-center faq-padding-inner' >
                <div className='w-[80%] faq-width'>
                    {faqData.map((faq, index) => (
                        <div key={index}>
                        <button
                            onClick={() => toggleAccordion(index)}
                            className={`w-full flex items-center justify-between p-3 focus:outline-none transition-all 
                            ${openIndex === index ? "" : "border-b border-black"}`}
                        >
                            <span className="mr-4" style={{ fontSize: `${paraFont}px`}}>
                            {openIndex === index ? '-' : '+'}
                            </span>
                            <span className="text-left font-semibold flex-grow" style={{ fontSize: `${paraFont}px`}}>
                            {language ? faq.question: faq.question2 }
                            </span>
                        </button>

                        {openIndex === index && (
                            
                            <div 
                            style={{ fontSize: `${paraFont}px`}}
                            className="px-3 pb-3 text-left border-b border-black"
                            >
                            <span className="mr-4 font-semibold" style={{ color:'#F4F5FB' , fontSize: `${paraFont}px`}}>
                            {openIndex === index ? '-' : '+'}
                            </span>
                            {language ? faq.answer: faq.answer2 }
                            </div>
                        )}
                        </div>
                    ))}
                    </div>

                </div>
            </div>
      </div>
      <WebFooter/>
    </div>
  )
}
