import React from 'react'
import arrow from "../../Assests/right-arrow.png"
import { showLang } from "../../Redux/Slices/globalLanguage";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import NewViewAllPage from './NewViewAllPage';
export default function NewsAndAnnouncement() {
  const navbarFont = useSelector((state) => state.navbarFontSlice);
  const paraFont = useSelector((state) => state.fontSizeSlice);
  const headingFont = useSelector((state) => state.headingFontSlice);
  const language = useSelector((state) => state.globalLanguage);
  const dispatch = useDispatch();
  const handleLanguage = (state) => {
    console.log(state)
    dispatch(showLang(state));
  };
  const navigate = useNavigate();
  return (
    <div className="bg-white p-6 rounded-lg shadow-lg newsandannounce-padding" style={{ padding: '20px 200px 10px'}}>
        
        <h2 
          className="font-semibold text-black mb-[20px] mx-auto w-fit" 
          style={{ fontSize: `${headingFont}px` }} 
        >
          {language ? "News & Announcements" : "समाचार एवं घोषणाएँ"} 
        </h2>
        
        <div className="space-y-5 bg-primarybg p-5 new-window" style={{ borderRadius: "24px"}}>
            {[
            { time: '03:00 pm', date: 'Feb 24', branch: 'Main Branch', branch2: 'मुख्य शाखा', title: 'New Books Came In, Lelo Books' , title2: 'नई किताबें आईं, लेलो बुक्स'},
            { time: '03:00 pm', date: 'Feb 24', branch: 'Main Branch', branch2: 'मुख्य शाखा', title: 'New Books Came In, Lelo Books' , title2: 'नई किताबें आईं, लेलो बुक्स'},
            { time: '03:00 pm', date: 'Feb 24', branch: 'Main Branch', branch2: 'मुख्य शाखा', title: 'New Books Came In, Lelo Books' , title2: 'नई किताबें आईं, लेलो बुक्स'},
            { time: '03:00 pm', date: 'Feb 24', branch: 'Main Branch', branch2: 'मुख्य शाखा', title: 'New Books Came In, Lelo Books' , title2: 'नई किताबें आईं, लेलो बुक्स'},
            ].map((item, index) => (
            <div key={index} 
                  className="flex justify-between items-center bg-white news-windows p-4  shadow-sm" 
                  style={{ borderRadius: "16px"}}
                >
                  <div>
                    <p className="text-smol text-left font-bold">
                      {`${item.time} | ${item.date} | ${language ? item.branch : item.branch2}`}
                    </p>

                    <h3 className="font-bold text-left mt-[10px]" 
                      style={{ fontSize: `${paraFont}px` }} 
                    >
                      {language ? item.title : item.title2}
                    </h3>
                  </div>

                <div className="bg-navblue p-3">
                    <img src={arrow}/>
                </div>
            </div>
            ))}
        </div>

        <div className="flex justify-end mt-4">
            <button 
            className="flex items-center pl-4 bg-navblue text-black mr-[54px] button-margin font-semibold"
            style={{ fontSize: `${paraFont}px` }} 
            onClick={() => navigate('/news-and-announcements')}
            >
            <span> {language ? "View All" : " सभी देखें"}</span>
            <div className="bg-navblue p-3">
                <img src={arrow}/>
            </div>
            </button>
        </div>
</div>

  )
}
