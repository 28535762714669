import React from 'react'
import { showLang } from "../../../Redux/Slices/globalLanguage";
import { useDispatch, useSelector } from "react-redux";
import archivecatalogue from '../../../Assests/Archive.xls'
import { FaQ } from 'react-icons/fa6';
import "../LibraryMainPage.css";
import { useNavigate } from 'react-router-dom';
export default function ImportantLinks() {
  const navbarFont = useSelector((state) => state.navbarFontSlice);
  const paraFont = useSelector((state) => state.fontSizeSlice);
  const headingFont = useSelector((state) => state.headingFontSlice);
  const language = useSelector((state) => state.globalLanguage);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLanguage = (state) => {
    console.log(state)
    dispatch(showLang(state));
  };
  const handleNavClick = (path) => {
    navigate(path);
    console.log(`Navigating to: ${path}`);
  };
  return (
    <div className="bg-white p-6 rounded-lg shadow-lg servicebox-padding" style={{ padding: '30px 50px'}}>
        <h2 className="font-semibold text-black mb-[20px] mx-auto w-fit" style={{ fontSize: `${headingFont}px`}}> {language ? "Important Links " :  'महत्वपूर्ण लिंक्स'}</h2>
        <div className="grid grid-cols-4 text-center implinks-window">
            {[
            {
              title:'IIT Roorkee Home', 
              title2:'IIT रूड़की मुख्य पृष्ठ' , 
              path:'https://www.iitr.ac.in/'
            },
            {
              title:'IIT Roorkee Webmail',
              title2:'IIT रूड़की वेबमेल', 
              path:'https://www.iitr.ac.in/Main/pages/_en_Webmail__en_.html'
            },
            {
              title:'Channel I',
              title2:'चैनल I', 
              path:'https://channeli.in/auth/login?next=/feed'
            },
            // {
            //   title:'Ph.D. Synopsis 15-16',
            //   title2:'Ph.D. सारांश 15-16', 
            //   path:'https://mgcl.iitr.ac.in/Docs/synopsis.pdf'
            // },
            // {
            //   title:'National Digital Library',
            //   title2:'राष्ट्रीय डिजिटल लाइब्रेरी', 
            //   path:'https://ndl.iitkgp.ac.in/'
            // },
            // {
            //   title:'Archival Collection MGCL, IITR',
            //   title2:'अभिलेखीय संग्रह MGCL, IITR', 
            //   path:'http://192.168.105.134/Archive/'
            // },
            {
              title:'Archive Catalogue',
              title2:'पुरालेख सूची', 
              path:{archivecatalogue},
              download: true
            },
            {
              title:'Ask a Librarian',
              title2:'किसी लाइब्रेरियन से पूछें', 
              path:'mailto:journals@iitr.ac.in'
            },
            {
              title:'Feedback/ Suggestions',
              title2:'प्रतिक्रिया/ सुझाव', 
              path:'https://mgcl.iitr.ac.in/feedback.php'
            },
            {
              title:'FAQs',
              title2:'पूछे जाने वाले प्रश्न', 
              onClick: () => handleNavClick("/faqs"),
            },
            ].map((item, index) => (
              <div key={index} className="flex justify-center  implinks-boxes">
              {item.onClick ? (
                <a
                  onClick={item.onClick}
                  style={{ fontSize: `${paraFont}px` }}
                  className="cursor-pointer bg-primarybg my-10 w-[190px] hover:bg-gray-100 px-1 py-4 rounded-3xl shadow-sm font-bold text-black"
                >
                  {language ? item.title : item.title2}
                </a>
              ) : (
                <a
                  href={item.path}
                  target="_blank"
                  rel="noopener noreferrer"
                  download={item.download}
                  style={{ fontSize: `${paraFont}px` }}
                  className="cursor-pointer bg-primarybg my-10 w-[190px] hover:bg-gray-100 px-1 py-4 rounded-3xl shadow-sm font-bold text-black"
                >
                  {language ? item.title : item.title2}
                </a>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
