import React, { useState, useEffect } from "react";
import frndimg from "../../Assests/1.jpeg";
import frndimg2 from "../../Assests/2.jpg";
import frndimg3 from "../../Assests/4.jpg";
import frndimg4 from "../../Assests/5.jpg";
import frndimg5 from "../../Assests/6.jpeg";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import { showLang } from "../../Redux/Slices/globalLanguage";
import { useDispatch, useSelector } from "react-redux";
export default function PhotoGallery() {
  const navbarFont = useSelector((state) => state.navbarFontSlice);
  const paraFont = useSelector((state) => state.fontSizeSlice);
  const headingFont = useSelector((state) => state.headingFontSlice);
  const language = useSelector((state) => state.globalLanguage);
  const dispatch = useDispatch();
  const handleLanguage = (state) => {
    console.log(state);
    dispatch(showLang(state));
  };
  const images = [frndimg2, frndimg, frndimg3, frndimg4, frndimg5];
  const [currentIndex, setCurrentIndex] = useState(0);
  const imagesPerPageDesktop = 6;
  const imagesPerPageMobile = 1;

  const isMobile = window.innerWidth <= 500;
  const imagesPerPage = isMobile ? imagesPerPageMobile : imagesPerPageDesktop;

  const startIndex = currentIndex;
  const endIndex = currentIndex + imagesPerPage;
  const visibleImages = images.slice(startIndex, endIndex);

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0
        ? images.length - imagesPerPage
        : prevIndex - imagesPerPage
    );
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + imagesPerPage >= images.length ? 0 : prevIndex + imagesPerPage
    );
  };

  useEffect(() => {
    const autoplayInterval = setInterval(() => {
      handleNextClick();
    }, 3000);

    return () => clearInterval(autoplayInterval);
  }, [currentIndex]);

  const StyledSlider = styled(Slider)`
    .slick-slide > div > div{
    display:flex !important;
    justify-content:center !important;
    align-items:center !important;
    }
`;
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1220,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 864,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div
      className="bg-primarybg p-6 rounded-lg shadow-lg servicebox-padding"
      style={{ padding: "30px 50px" }}
    >
      <h2
        className="font-semibold text-black mb-[20px] mx-auto w-fit"
        style={{ fontSize: `${headingFont}px` }}
      >
        {language ? "Photo Gallery" : "फोटो गैलरी"}
      </h2>

      <StyledSlider {...settings}>
    {visibleImages.map((imgSrc, index) => (
      <div key={index} className="flex justify-center items-center">
        <div className="text-center overflow-hidden rounded-3xl w-[85%] photogalleryw">
          <img
            src={imgSrc}
            alt={`Gallery item ${index + 1}`}
            className="rounded-lg"
          />
        </div>
      </div>
    ))}
  </StyledSlider>
    </div>
  );
}
  