import React from 'react'
import "../LibraryMainPage.css";
import { showLang } from "../../../Redux/Slices/globalLanguage";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import RequestForBooksAndResearchPpr from './RequestForBooksAndResearchPpr';
import onlinelib from "../../../Assests/online-library.png"
export default function ServicesInBoxes() {
  const navbarFont = useSelector((state) => state.navbarFontSlice);
  const paraFont = useSelector((state) => state.fontSizeSlice);
  const headingFont = useSelector((state) => state.headingFontSlice);
  const language = useSelector((state) => state.globalLanguage);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLanguage = (state) => {
    console.log(state)
    dispatch(showLang(state));
  };
  const handleNavClick = (path) => {
    navigate(path);
  };
  return (
    <div className="bg-primarybg px-[50px] py-10 servicebox-padding">
  <div className="grid grid-cols-6 gap-y-10 servicesinbox-window">
    {[
      { title: 'User Guides', 
        icon: '📖'  , 
        title2 : "उपयोगकर्ता गाइड",
        path:'https://mgcl.iitr.ac.in/guide.html' 
      },
      { title: 'Online Catalogue (OPAC)', 
        icon: '📑'  , 
        title2 : "ऑनलाइन कैटलॉग (OPAC)",
        path:'http://opac.mgcl.iitr.ac.in/' 
      },
      { title: 'Search e-Book Catalogue', 
        icon: '🔍' , 
        title2 : "ई-बुक कैटलॉग खोजें" ,
        path:'http://ebooks.mgcl.iitr.ac.in//' 
      },
      { title: 'eLibrary - Remote Access Portal', 
        icon: '🌐' , 
        title2:" ई-लाइब्रेरी - रिमोट एक्सेस पोर्टल",
        path:'https://elibrary.mgcl.iitr.ac.in/user#/home' 
      },
      { title: 'My Account @MG Central Library', 
        icon: '👤' , 
        title2 : " मेरी प्रोफाइल @भारतीय प्रौद्योगिकी संस्थान",
        path:'http://opac.mgcl.iitr.ac.in/cgi-bin/koha/opac-user.pl'  
      },
      { title: 'Recent Arrivals', 
        icon: '⏰' , 
        title2 : "हाल के आगमन",
        path:'https://opac.mgcl.iitr.ac.in/cgi-bin/koha/opac-shelves.pl?op=view&shelfnumber=1&sortfield=title'  
      },
      { title: 'Book Purchase Request', 
        icon: '📘' , 
        title2 : "पुस्तक खरीद अनुरोध" ,
        onClick: () => handleNavClick("/requests"), 
      },
      { title: 'Request for Research Paper', 
        icon: '🔬' , 
        title2 : "शोध पत्र के लिए अनुरोध" ,
        onClick: () => handleNavClick("/requests"),
      },
      { title: 'J-Gate (Journal Search)', 
        icon: '✍️' , 
        title2 : "जे-गेट (जर्नल सर्च)",
        path:'https://jgatenext.com/' 
      },
      { title: 'Bhagirathi: Archival Collection IR', 
        icon: '🗂️' , 
        title2 : "भागीरथी: अरुचिवाल कलेक्शन IR" ,
        path:'http://10.41.0.25:8080/xmlui/' 
      },
      { title: 'National Digital Library', 
        icon: '📚'  , 
        title2 : "राष्ट्रीय डिजिटल लाइब्रेरी",
        path:'https://ndl.iitkgp.ac.in/' 
      },
      { title: 'Departmental Libraries Collection', 
        icon: '🏛️' , 
        title2 : "विभागीय पुस्तकालय संग्रह" ,
        path:'http://192.168.105.134/Deptt%20Lib%20Data/books_collection_of_a_department.htm' 
      },
    ].map((item, index) => (
      <div className="flex justify-center" key={index}>
        {item.onClick ? (
                <a
                  onClick={item.onClick}
                  className="service-boxes-w bg-white px-2 py-8 shadow-lg rounded-3xl text-center w-[180px] min-w-[160px] cursor-pointer"
                >
                   <div className="text-5xl mb-4">{item.icon}</div>
                  <h3 className="font-bold text-gray-800" style={{ fontSize: `${paraFont}px` }}>
                    {language ? item.title : item.title2}
                  </h3>
                </a>
              ) : (
                <a
                  href={item.path}
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="service-boxes-w bg-white px-2 py-8 shadow-lg rounded-3xl text-center w-[180px] min-w-[160px] cursor-pointer"
                >
                
                  <div className="text-5xl mb-4">{item.icon}</div>
                  <h3 className="font-bold text-gray-800" style={{ fontSize: `${paraFont}px` }}>
                    {language ? item.title : item.title2}
                  </h3>
                </a>
              )}
        </div>
        ))}
      </div>
    </div>

  )
} 